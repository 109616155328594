import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Container from '../layouts/container';
import Testimonial from '../components/testimonial/testimonial';

import styles from './about.module.scss';

import { textContent, testimonial } from '../../content/pages/about.json';

const PageAbout = ({ data }) => (
  <Container>
    <div className={styles.aboutContainer}>
      <div className={styles.profileImage}>
        <Img fluid={data.file.childImageSharp.fluid} loading="eager" />
      </div>
      <div className={styles.textContent}>
        { textContent
          .map((paragraph, i) => <p key={i} className={styles[paragraph.className || 'paragraph']}>{paragraph.text}</p>) }
        <Testimonial attribution={testimonial.attribution}>{testimonial.text}</Testimonial>
      </div>
    </div>
  </Container>
);

export const query = graphql`
  query {
    file(relativePath: { eq: "profile.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default PageAbout;
